<template>
    <section class="gardenManagementPage">
 

        <TableContainer title="">

            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="id" label="序号" min-width="60" />
                <el-table-column prop="organizationName" label="所属部门" min-width="60" />
                <el-table-column prop="videoImg" label="视频缩略图" min-width="60">
                    <template slot-scope="scope">
                        <div class="p-r" @click="showVideo(scope.row)">
                            <img :src="scope.row.videoImg" width="60" height="60" />
                            <i class="el-icon-video-play f-32 p-a col-f" style="top:50%;left:50%;transform: translate(-50%, -50%);"></i>
                        </div>
                        
                      

                    </template>
                </el-table-column>
                <el-table-column prop="videoTitle" label="视频名称" min-width="60" />
                <el-table-column prop="videoPlay" label="播放次数" min-width="60" />
                <el-table-column prop="catName" label="视频分类" min-width="60" />
                <el-table-column prop="createTime" label="发布时间" min-width="60" />
                
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="textOrange" @click="updatePxVideo(scope.row)">恢复上架</el-button>
                        <!-- <el-button type="text" class="textOrange" @click="dissolutionFormClick(scope.row)">编辑</el-button> -->
                        <el-button type="text" class="textOrange" @click="delteFormClick(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryPxVideoPage" />
        </TableContainer>


        <!-- <Tinymce :height="200" v-model="tiny.value"></Tinymce> -->

        <!-- <button @click="buttonClick">提交</button> -->

        <el-dialog :visible.sync="themeDialogs.visible" width="800px">
            <div class="dis-flex flex-x-center">
                <video :src="video" poster="" type="video/mp4,audio/mp3"  controls="controls" preload="auto" style="display: block; width: 700px; height: 500px;">
                                您的浏览器不支持视频播放
                            </video>
             
            </div>
           
            
        </el-dialog>


    </section>
</template>

<script>
import Tinymce from "./tinymce";
import {
    bxUser,
    addPxNotice,
    queryPxNotice,
    updatePxNotice,
    queryPxVideoPage,
    updatePxVideo,
    delPxVideo

} from '@api/bx/api_contentManagement';
export default {
    components: { Tinymce },
    data() {

        return {
            tiny: {
                value: undefined
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            themeDialogs: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10,
                videoState:'1'
            },
            total: 0,
            tableData: [],
            typeList: [],
            dicCode: null,
            addCode: null,
            addItem: {},
            dicName: '',
            isEditor: false,
            editorRow: {},
            text: '',
            dishList: [],
            dayTime: '',
            video:'',
            // showVideo:false
        }
    },
    created() {
        this.queryPxVideoPage()

    },
    methods: {
        findDcUser(){},
        selectTime() { },
        changeType() {

        },
        	 //查看二维码
		 showVideo(row){
			this.video = row.videoUrl
            this.themeDialogs.visible=true
            // createDockorTitket({userId:row.userId}).then(res => {
            //     this.image = res
              

            // })
        },
        addVideo() {
            this.$router.push('/bxPxCatMenu/addVideo')
        },
        // 保存富文本内容-保存字符串
        add() {
            const params = `this.tiny?.value`
        },
        edit() {
            // 接口回显保存html
            // this.tiny.value = res.data|| ''
        },

        buttonClick() {
            //首次提交
            //     addPxNotice({ 
            //         noticeTitle: '测试',
            //         noticeContent:this.tiny.value,
            //         noticeImg:''

            //  }).then(res => {
            //             this.$message.success('上传成功')

            //         })
            //编辑提交
            updatePxNotice({
                noticeTitle: '编辑测试',
                noticeContent: this.tiny.value,
                id: 836,
                noticeImg: ''

            }).then(res => {
                this.$message.success('上传成功')

            })
            // console.log(this.tiny.value)


        },
        //文章复现
        queryPxNotice() {
            // console.log(localStorage.getItem('userId').data)
            // return
            queryPxNotice({
                // userId: localStorage.getItem('userId').data,
            }).then(res => {
                console.log(res)
                // this.$message.success('上传成功')
                // this.bxUser()
                this.tiny.value = res[1].noticeContent
            })
            console.log(this.tiny.value)
        },


        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },

        // 视频列表
        queryPxVideoPage() {
            queryPxVideoPage(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total

            })
        },

         //下架
         updatePxVideo(row) {
            this.$confirm('是否恢复上架该视频?', '提示', {
                confirmButtonText: '上架',
                cancelButtonText: '关闭',
                type: 'warning'
            }).then(() => {
                updatePxVideo({
                     id:row.id,
                     videoState:'0',
                     }).then(res => {
                    this.$message.success('上架成功')
                    this.queryPxVideoPage()
                })
            })
        },

        //解绑
        dissolutionFormClick(row) {
            this.$confirm('是否脱离关系?', '提示', {
                confirmButtonText: '脱离关系',
                cancelButtonText: '关闭',
                type: 'warning'
            }).then(() => {
                bxUserUnbinding({ userId: row.userId }).then(res => {
                    this.$message.success('解绑成功')
                    this.bxUser()
                })
            })
        },
        //表格删除
        delteFormClick(row) {
            this.$confirm('永久删除' + '这条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delPxVideo({ id: row.id }).then(res => {
                	this.queryPxVideoPage()
                })
            })

        }


    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

